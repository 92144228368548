import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { DeleteUsuarioPlataforma } from "../../../services/request/usuarios/usuariosPlataforma";

export default function DialogDelete(props) {
  const {
    is_open,
    data,
    handle_close,
    mutate_usuarios,
  } = props;

  const notistack = useSnackbar();

  const handleDelete = async () => {
    try {
      notistack.enqueueSnackbar("Eliminando el usuario de plataforma...", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
      });
      await DeleteUsuarioPlataforma(data._id);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar("Usuario eliminado exitosamente.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
      });
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar("Error al intentar eliminar el usuario.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
      });
    } finally {
      mutate_usuarios();
      handle_close();
    }
  }

  return (
    <Dialog open={is_open} onClose={handle_close} maxWidth="md">
      <DialogTitle>Eliminar Usuario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Debe confirmar la eliminación del usuario "${data ? `${data._persona_ref.nombre} ${data._persona_ref.apellido_paterno} ${data._persona_ref.apellido_materno}` : ""}".`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_close} color="primary">Cancelar</Button>
        <Button onClick={handleDelete} variant="contained" color="primary">Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
}