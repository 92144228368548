import React, { Fragment, useContext, useState } from "react";
import MaterialTable from "../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import { MainContext } from "../../../App";
import * as Permisos from "../../../constants/permissions";

function Page(props) {
	let {
		usuarios,
		is_loading,
		mutate_usuarios,
	} = props;

	const [IsOpen, SetIsOpen] = useState({});
	const { permisos } = useContext(MainContext);

	if (usuarios && usuarios.data) {
		usuarios.data = usuarios.data.filter(d => d._persona_ref).map(u => ({
			...u,
			nombre_completo: `${u._persona_ref.nombre} ${u._persona_ref.apellido_paterno} ${u._persona_ref.apellido_materno}`,
		}));
	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre_completo",
		}, {
			title: "Correo",
			field: "_persona_ref.contacto.email",
		}, {
			title: "Cant. Permisos",
			field: "permisos.length",
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			hidden: !permisos.includes(Permisos.USUARIOS_PLATAFORMA_EDITAR),
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			hidden: !permisos.includes(Permisos.USUARIOS_PLATAFORMA_ELIMINAR),
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
		}, {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			hidden: !permisos.includes(Permisos.USUARIOS_PLATAFORMA_AGREGAR),
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: null }),
		},
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	return (
		<Fragment>
			{/* LISTADO DE USUARIOS PLATAFORMA */}
			<MaterialTable
				title="Usuarios"
				is_loading={is_loading}
				data={usuarios?.data}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					usuarios={usuarios?.data}
					handle_close={handleCloseDialog}
					mutate_usuarios={mutate_usuarios}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_usuarios={mutate_usuarios}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_usuarios={mutate_usuarios}
				/>
			)}
		</Fragment>
	);
}

export default Page;