import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import TabSelector from "../../../components/tab_selector";
import { UpdateUsuarioPlataforma } from "../../../services/request/usuarios/usuariosPlataforma";
import { GetPersonas } from "../../../services/request/usuarios/personas";
import { FormikInitialValues, FormikValidationSchema, PERMISOS } from "./utils";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_usuarios,
	} = props;

	const PersonasSWR = useSWR("personas", (key) => GetPersonas({ is_gsuite_talana: true }), { revalidateOnFocus: false });
	const notistack = useSnackbar();
	const [TabIndex, SetTabIndex] = useState(0);
	const [Permisos, SetPermisos] = useState(PERMISOS.map(p => ({ ...p, checked: false })));
	const TiposPermisos = useMemo(() => Array.from(new Set(PERMISOS.map(p => p.group))), []);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Actualizando el usuario de plataforma...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				values["_id"] = data._id;
				values["_persona_ref"] = values.persona._id;
				values["permisos"] = values.permisos.filter(p => p.checked).map(p => p.value);
				await UpdateUsuarioPlataforma(values);
				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Usuario actualizado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar actualizar el usuario.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} finally {
				helper.resetForm();
				mutate_usuarios();
				handle_close();
			}
		}
	});

	useEffect(() => {
		if (data) {
			for (let i = 0; i < Permisos.length; i++) {
				if (data.permisos.includes(Permisos[i].value)) {
					Permisos[i]["checked"] = true;
				}
			}
			formik.resetForm({
				values: {
					persona: data._persona_ref,
					permisos: Permisos,
				}
			});
		}
	}, [data])

	/**
	 * Método encargado de 
	 * @param {*} checked FLAG de permiso activado o no.
	 * @param {*} permiso Datos del permiso.
	 */
	const handleToggle = (checked, permiso) => {
		let permisos = Array.from(Permisos);
		for (const p of permisos) {
			if (p.value === permiso.value) {
				p.checked = checked;
			}
		}
		SetPermisos(permisos);
		formik.setFieldValue("permisos", permisos);
	};

	/**
	 * Método encargado de activar todos los toggles de un grupo.
	 * @param {*} checked Valor del toggle.
	 * @param {*} tipoPermiso Tipo de permiso.
	 */
	const handleToggleGroup = (checked, tipoPermiso) => {
		let permisos = Array.from(Permisos);
		for (const p of permisos) {
			if (p.group === tipoPermiso) {
				p.checked = checked;
			}
		}
		formik.setFieldValue("permisos", permisos);
		notistack.enqueueSnackbar(`Asignados todos los permisos del grupo "${tipoPermiso}".`, {
			variant: "success",
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
		});
	}

	/**
	 * Método encargado de activar todos los toggles de permisos.
	 * @param {*} checked Valor del toggle.
	 */
	const handleToggleAll = (checked = true) => {
		let permisos = Array.from(Permisos);
		for (const p of permisos) {
			p.checked = checked;
		}
		SetPermisos(permisos);
		formik.setFieldValue("permisos", permisos);
		notistack.enqueueSnackbar("Asignados todos los permisos.", {
			variant: "success",
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
		});
	}

	/**
	 * Metodo encargado de generar un listado unico de grupos de permisos.
	 * @returns Coleccioon de grupos de permisos, con detalle de las cantidades seleccionadps y totales.
	 */
	const TitulosTabs = () => {
		let cantPermisosGrupo = (grupo) => Permisos.filter(permiso => permiso.group === grupo).length;
		let cantPermisosAsignados = (grupo) => Permisos.filter(permiso => permiso.group === grupo && permiso.checked === true).length;
		return TiposPermisos.map(grupo => `${grupo} (${cantPermisosAsignados(grupo)} de ${cantPermisosGrupo(grupo)})`);
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md">
			<DialogTitle>Actualizar Usuario</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria del usuario y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					{/* SELECTOR DE PERSONAS */}
					<Grid item xs={12}>
						<Autocomplete
							options={[data._persona_ref]}
							value={formik.values.persona}
							getOptionLabel={(persona) => `[${persona.contacto.email}] ${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno}`}
							onChange={(event, value) => formik.setFieldValue("persona", value)}
							loading={PersonasSWR.isValidating}
							disabled={true}
							renderInput={(params) => (
								<TextField
									label="Persona"
									helperText={formik.errors.persona}
									error={Boolean(formik.errors.persona)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* BOTON PARA ASIGNAR TODOS LOS PERMISOS DE TODOS LOS GRUPOS */}
					<Grid item xs={2}>
						<Button onClick={() => handleToggleAll(true)} disabled={!data} variant="contained" color="secondary">
							Asignar todo
						</Button>
					</Grid>

					{/* PESTAÑA CON GRUPOS DE PERMISOS */}
					<Grid item xs={12}>
						<TabSelector
							tab_list={TitulosTabs()}
							tab_index={TabIndex}
							handle_change={(event, index) => SetTabIndex(index)}
						/>

						{/* BOTON PARA ASIGNAR TODOS LOS PERMISOS DEL GRUPO */}
						<Box display="flex" justifyContent="flex-end" style={{ margin: 10 }}>
							<Button onClick={(event) => handleToggleGroup(true, TiposPermisos[TabIndex])} disabled={!data} variant="outlined" color="secondary" size="small">
								{`Asignar todo ${TiposPermisos[TabIndex]}`}
							</Button>
						</Box>

						{/* COLECCION DE PERMISOS */}
						<List>
							{Permisos.filter(p => p.group === TiposPermisos[TabIndex]).map((permiso, index2) => (
								<ListItem divider dense key={`list_item_${index2}`}>
									<ListItemText primary={permiso.label} />
									<ListItemSecondaryAction>
										<Switch
											edge="end"
											onChange={(event, checked) => handleToggle(checked, permiso)}
											checked={Boolean(permiso.checked)}
											disabled={!data}
											size="small"
										/>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}