import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
	typography: {
		fontSize: 14,
		htmlFontSize: 17,
		fontFamily: "Poppins",
	},
	palette: {
		primary: {
			light: "#a64c46",
			main: "#731f1f",
			dark: "#430000",
			contrastText: "#ffffff",
		},
		secondary: {
			light: "#4f5b62",
			main: "#263238",
			dark: "#000a12",
			contrastText: "#ffffff",
		},
		text: {
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)",
		},
	},
});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<SnackbarProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</SnackbarProvider>
	</MuiThemeProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
