import React, { Fragment, useContext, useState } from "react";
import { IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiOpenInNew } from "@mdi/js";
import MaterialTable from "../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import { MainContext } from "../../../App";
import * as Permisos from "../../../constants/permissions";
import { TIPOS_APLICACION } from "./utils";

function Page(props) {
  let {
    aplicaciones,
    is_loading,
    mutate_aplicaciones,
  } = props;

  const [IsOpen, SetIsOpen] = useState({});
  const { permisos } = useContext(MainContext);

  //Se reemplaza el valor del tipo de aplicación por un texto.
  if (aplicaciones) {
    aplicaciones.forEach((aplicacion, index) => {
      let tipoApp = TIPOS_APLICACION.find(t => t.value === aplicacion.tipo_app);
      aplicaciones[index].tipo_app_name = tipoApp ? tipoApp.label : undefined;
    });
  }

  let columns = [
    {
      title: "Nombre",
      field: "nombre",
    }, {
      title: "Nombre Custom",
      field: "nombre_custom",
    }, {
      title: "Contrato",
      field: "contrato_ref.codigo",
      cellStyle: {
        whiteSpace: "nowrap",
      }
    }, {
      title: "Categoría",
      field: "categoria",
    }, {
      title: "Tipo",
      field: "tipo",
    }, {
      title: "Tipo App",
      field: "tipo_app_name",
    }, {
      title: "Categoría",
      field: "categoria",
    }, {
      title: "Descripción",
      field: "descripcion",
    }, {
      title: "Ayuda",
      field: "link_ayuda",
      render: (row) => BotonLinkExterno(row.link_ayuda),
    }, {
      title: "Carpeta PDFs",
      field: "link_carpeta_pdfs",
      render: (row) => BotonLinkExterno(row.link_carpeta_pdfs),
    }, {
      title: "Carpeta Imagenes",
      field: "link_carpeta_imagenes",
      render: (row) => BotonLinkExterno(row.link_carpeta_imagenes),
    }, {
      title: "Template",
      field: "link_template",
      render: (row) => BotonLinkExterno(row.link_template),
    }, {
      title: "Template Cierre",
      field: "link_template_cierre",
      render: (row) => BotonLinkExterno(row.link_template_cierre),
    }, {
      title: "Logo",
      field: "logo",
      render: (row) => BotonLinkExterno(row.logo),
    },
  ];

  let actions = [
    {
      icon: "edit",
      tooltip: "Actualizar",
      hidden: !permisos.includes(Permisos.APLICACIONES_EDITAR),
      onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
    }, {
      icon: "delete",
      tooltip: "Eliminar (Requiere confirmación)",
      hidden: !permisos.includes(Permisos.APLICACIONES_ELIMINAR),
      onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
    }, {
      icon: "add",
      tooltip: "Agregar",
      isFreeAction: true,
      hidden: !permisos.includes(Permisos.APLICACIONES_AGREGAR),
      onClick: (event, row) => SetIsOpen({ tipo: "add", data: row }),
    },
  ];

  const handleCloseDialog = () => {
    SetIsOpen({});
  }

  return (
    <Fragment>
      {/* LISTADO DE APLICACIONES */}
      <MaterialTable
        title="Aplicaciones"
        is_loading={is_loading}
        data={aplicaciones}
        columns={columns}
        actions={actions}
        export_excel={true}
        export_spreadsheet={true}
      />

      {/* DIALOG PARA AGREGAR */}
      {IsOpen.tipo === "add" && (
        <DialogAdd
          is_open={IsOpen.tipo === "add"}
          handle_close={handleCloseDialog}
          mutate_aplicaciones={mutate_aplicaciones}
        />
      )}

      {/* DIALOG PARA ACTUALIZAR */}
      {IsOpen.tipo === "update" && (
        <DialogUpdate
          is_open={IsOpen.tipo === "update"}
          data={IsOpen.data}
          handle_close={handleCloseDialog}
          mutate_aplicaciones={mutate_aplicaciones}
        />
      )}

      {/* DIALOG PARA ELIMINAR */}
      {IsOpen.tipo === "delete" && (
        <DialogDelete
          is_open={IsOpen.tipo === "delete"}
          data={IsOpen.data}
          handle_close={handleCloseDialog}
          mutate_aplicaciones={mutate_aplicaciones}
        />
      )}
    </Fragment>
  );
}

function BotonLinkExterno(link) {
  if (!link) {
    return null;
  }

  return (
    <IconButton href={link} target="_blank" size="small">
      <Icon path={mdiOpenInNew} size={1} />
    </IconButton>
  );
}

export default Page;