import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon } from "@mdi/react";
import { mdiCancel } from '@mdi/js';

export default function AccessDenied(props) {

	return (
		<Box Box display="flex" justifyContent="center" alignItems="center">
			<Icon path={mdiCancel} title="Acceso Denegado" size={10} />
			<Typography variant="h5">Acceso Denegado</Typography>
		</Box>
	);
}