//PERMISO VER AJUSTES
export const AJUSTES_VER = "ajustesVer";

//PERMISOS VER INSPECCIONES
export const INSPECCIONES_VER = "inspeccionesVer";

//PERMISOS ADMINISTRACION APLICACIONES
export const APLICACIONES_VER = "aplicacionesVer";
export const APLICACIONES_AGREGAR = "aplicacionesAgregar";
export const APLICACIONES_EDITAR = "aplicacionesEditar";
export const APLICACIONES_ELIMINAR = "aplicacionesEliminar";

//PERMISOS ADMINISTRACION PERSONAS
export const PERSONAS_VER = "personasVer";
export const PERSONAS_VER_CREDENCIAL = "personasVerCredencial";
export const PERSONAS_AGREGAR = "personasAgregar";
export const PERSONAS_EDITAR = "personasEditar";
export const PERSONAS_ELIMINAR = "personasEliminar";

//PERMISOS ADMINISTRACION PERSONAS TALANA
export const PERSONAS_TALANA_VER = "personasTalanaVer"

//PERMISOS ADMINISTRACION ROLES
export const ROLES_VER = "rolesVer";
export const ROLES_AGREGAR = "rolesAgregar";
export const ROLES_EDITAR = "rolesEditar";
export const ROLES_ELIMINAR = "rolesEliminar";

//PERMISOS ADMINISTRACION USUARIOS PLATAFORMA
export const USUARIOS_PLATAFORMA_VER = "usuariosPlataformaVer";
export const USUARIOS_PLATAFORMA_AGREGAR = "usuariosPlataformaAgregar";
export const USUARIOS_PLATAFORMA_EDITAR = "usuariosPlataformaEditar";
export const USUARIOS_PLATAFORMA_ELIMINAR = "usuariosPlataformaEliminar";

//PERMISOS ADMINISTRACION USUARIOS EXCEPCIÓN
export const USUARIOS_EXCEPCION_VER = "usuariosExcepcionVer";
export const USUARIOS_EXCEPCION_AGREGAR = "usuariosExcepcionAgregar";
export const USUARIOS_EXCEPCION_EDITAR = "usuariosExcepcionEditar";
export const USUARIOS_EXCEPCION_ELIMINAR = "usuariosExcepcionEliminar";

//PERMISOS ADMINISTRACION GENERAR QR
export const USUARIOS_GENERADOR_QR = "usuariosGeneradorQR";