import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los usuarios excepción
 * @returns Colección de usuarios excepción
 */
export async function GetUsuariosExcepcion() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-excepcion`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener un usuario excepción, por su ID.
 * @param {*} usuarioExcepcionID ID del usuario excepción.
 * @returns Datos del usuario excepción.
 */
export async function GetUsuarioExcepcion(usuarioExcepcionID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-excepcion/${usuarioExcepcionID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un usuario.
 * @param {*} usuarioExcepcion Datos del usuario excepción.
 * @returns Dato agregado.
 */
export async function AddUsuarioExcepcion(usuarioExcepcion) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-excepcion`;
    let response = await Axios.post(url, usuarioExcepcion);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un usuario excepción.
 * @param {*} usuarioExcepcion Datos del usuario excepción.
 * @returns Dato actualizado.
 */
export async function UpdateUsuarioExcepcion(usuarioExcepcion) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-excepcion/${usuarioExcepcion._id}`;
    let response = await Axios.put(url, usuarioExcepcion);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un usuario excepción.
 * @param {*} usuarioExcepcionID ID del usuario excepción.
 * @returns Respuesta de la eliminación.
 */
export async function DeleteUsuarioExcepcion(usuarioExcepcionID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-excepcion/${usuarioExcepcionID}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}