import React from "react";
import { Switch } from "@material-ui/core";
import useSWR from "swr";
import MaterialTable from "../../../components/materialTable";
import { GetProyectosContrato } from "../../../services/request/proyectos/proyectos";

export default function TablaProyectosVisibles(props) {
  const {
    seleccionados,
    contratos,
    handle_toggle,
  } = props;

  const { data: proyectos, isValidating: isValidatingProyectos } = useSWR("proyectos_proyectos", GetProyectosContrato, { revalidateOnFocus: false });

  if (proyectos) {
    //Se incluye flag CHECKED.
    proyectos.forEach((proyecto, index) => {
      let check = seleccionados.includes(proyecto._id);
      proyectos[index].checked = check;
    });

    //Se ordena por los proyectos seleccionados.
    proyectos.sort((a, b) => b.checked - a.checked);
  }

  let columns = [
    {
      title: "Visibilidad",
      removable: false,
      sorting: false,
      render: row => <Switch checked={row.checked} onClick={(event) => handle_toggle(row._id)} color="primary" />,
    }, {
      title: "Código",
      field: "codigo",
    }, {
      title: "Nombre",
      field: "nombre",
      render: (row) => <label style={{ width: 250 }}>{row.nombre}</label>,
    }, {
      title: "Contrato",
      render: (row) => <label style={{ width: 250 }}>{`[${row.contrato_codigo}] ${row.contrato_nombre}`}</label>,
    }, {
      title: "Código Contrato",
      field: "contrato_codigo",
      hidden: true,
    }, {
      title: "Nombre contrato",
      field: "contrato_nombre",
      hidden: true,
    }
  ];

  return (
    <MaterialTable
      title={CalcularCantidades(seleccionados, proyectos?.filter(p => contratos.includes(p.contrato_ref)))}
      is_loading={isValidatingProyectos}
      data={proyectos?.filter(p => contratos.includes(p.contrato_ref))}
      columns={columns}
      filtering={false}
      grouping={false}
      exportButton={false}
    />
  );
}

/**
 * Método encargado de calcular la cantidad de seleccionados sobre el total.
 * @param {*} seleccionados Cantidad de seleccionados.
 * @param {*} total Cantidad total.
 * @returns Cantidades con formato X1 / X2.
 */
function CalcularCantidades(seleccionados, total) {
  let cantidad1 = seleccionados ? seleccionados.length : 0;
  let cantidad2 = total ? total.length : 0;
  return `Proyectos Seleccionados: ${cantidad1} / ${cantidad2}`;
}