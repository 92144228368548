import React, { createContext, useEffect, useState } from "react";
import Page from "./page";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GetPersonas, AddPersonaProyectos } from "../../../services/request/usuarios/personas";
import { GetAplicaciones } from "../../../services/request/usuarios/aplicaciones";

export const PersonasContext = createContext({
	is_habilitado: Boolean(),
	is_responsable: Boolean(),
	is_cliente: Boolean(),
	is_externo: Boolean(),
	is_gsuite: Boolean(),
	is_talana: Boolean(),
	is_app_inspecciones: Boolean(),
	is_app_libro_obra: Boolean(),
	is_app_bitacora: Boolean(),
	is_app_sgi: Boolean(),
	is_app_pod: Boolean(),
	is_app_art: Boolean(),
	handle_filters: () => { },
});

function Index(props) {
	const [IsHabilitado, SetIsHabilitado] = useState(false);
	const [IsResponsable, SetIsResponsable] = useState(false);
	const [IsCliente, SetIsCliente] = useState(false);
	const [IsExterno, SetIsExterno] = useState(false);
	const [IsGSuite, SetIsGSuite] = useState(true);
	const [IsTalana, SetIsTalana] = useState(false);
	const [IsAppInspecciones, SetIsAppInspecciones] = useState(false);
	const [IsAppLibroObra, SetIsAppLibroObra] = useState(false);
	const [IsAppBitacora, SetIsAppBitacora] = useState(false);
	const [IsAppSGI, SetIsAppSGI] = useState(false);
	const [IsAppPOD, SetIsAppPOD] = useState(false);
	const [IsAppART, SetIsAppART] = useState(false);

	const { data, isValidating, mutate, error } = useSWR("personas", (key) => GetPersonas(), { revalidateOnFocus: false });
	const { data: aplicaciones } = useSWR("apps", GetAplicaciones, { revalidateOnFocus: false });
	const notistack = useSnackbar();

	useEffect(() => {
		if (error) {
			notistack.enqueueSnackbar("Error al intentar cargar los datos de las personas.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		}
	}, [notistack, error]);

	/**
	 * Método encargado de filtrar las personas, de acuerdo a los filtros seleccionados.
	 * @returns Colección de personas, filtradas según corresponda.
	 */
	const personasFilter = () => {
		return data?.filter(p =>
			(IsHabilitado ? p.is_habilitado === true : true) &&
			(IsResponsable ? p.is_responsable === true : true) &&
			(IsCliente ? p.is_cliente === true : true) &&
			(IsExterno ? p.is_externo === true : true) &&
			(IsGSuite ? p.is_gsuite === true : true) &&
			(IsTalana ? p.is_talana === true : true) &&
			(IsAppInspecciones && aplicaciones ? HasApp(aplicaciones, "1", p.aplicaciones_visibles_ref) : true) &&
			(IsAppLibroObra && aplicaciones ? HasApp(aplicaciones, "2", p.aplicaciones_visibles_ref) : true) &&
			(IsAppBitacora && aplicaciones ? HasApp(aplicaciones, "3", p.aplicaciones_visibles_ref) : true) &&
			(IsAppSGI && aplicaciones ? HasApp(aplicaciones, "4", p.aplicaciones_visibles_ref) : true) &&
			(IsAppPOD && aplicaciones ? HasApp(aplicaciones, "5", p.aplicaciones_visibles_ref) : true) &&
			(IsAppART && aplicaciones ? HasApp(aplicaciones, "6", p.aplicaciones_visibles_ref) : true)
		);
	}

	/**
	 * Método encargado de activar y/o desactivar filtros de personas.
	 * @param {*} name Nombre del filtro seleccionado.
	 * @param {*} checked Nuevo valor del checkbox.
	 */
	const handleFilters = (name, checked) => {
		switch (name) {
			case "is_habilitado":
				SetIsHabilitado(checked);
				break;
			case "is_responsable":
				SetIsResponsable(checked);
				break;
			case "is_cliente":
				SetIsCliente(checked);
				break;
			case "is_externo":
				SetIsExterno(checked);
				break;
			case "is_gsuite":
				SetIsGSuite(checked);
				break;
			case "is_talana":
				SetIsTalana(checked);
				break;
			case "is_app_inspecciones":
				SetIsAppInspecciones(checked);
				break;
			case "is_app_libro_obra":
				SetIsAppLibroObra(checked);
				break;
			case "is_app_bitacora":
				SetIsAppBitacora(checked);
				break;
			case "is_app_sgi":
				SetIsAppSGI(checked);
				break;
			case "is_app_pod":
				SetIsAppPOD(checked);
				break;
			case "is_app_art":
				SetIsAppART(checked);
				break;
			default:
				break;
		}
	}

	/**
	 * Método encargado de copiar un dato al portapepeles.
	 * @param {*} mensaje Mensaje para el usuario.
	 * @param {*} id Valor del ID.
	 * @see https://stackoverflow.com/a/52033479 Referencia para Clipboard en React JS.
	 */
	const handleCopy = (mensaje, id) => {
		navigator.clipboard.writeText(id);
		notistack.enqueueSnackbar(`${mensaje} copiado.`);
	}

	/**
	 * Método encargado de agregar una persona a la plataforma de Proyectos.
	 * @param {*} personaID ID de la persona.
	 */
	const handleAddToProyectos = (personaID) => {
		notistack.enqueueSnackbar(`Agregando a la persona en la Plataforma de Proyectos...`, {
			variant: "info",
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
		});
		AddPersonaProyectos(personaID)
			.then(() => {
				notistack.enqueueSnackbar(`Persona agregada en Plataforma de Proyectos exitosamente.`, {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			})
			.catch(error => {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar agregar a la persona en Plataforma de Proyectos.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			})
			.finally(() => {
				mutate();
			});
	}

	return (
		<PersonasContext.Provider
			value={{
				is_habilitado: IsHabilitado,
				is_responsable: IsResponsable,
				is_cliente: IsCliente,
				is_externo: IsExterno,
				is_gsuite: IsGSuite,
				is_talana: IsTalana,
				is_app_inspecciones: IsAppInspecciones,
				is_app_libro_obra: IsAppLibroObra,
				is_app_bitacora: IsAppBitacora,
				is_app_sgi: IsAppSGI,
				is_app_pod: IsAppPOD,
				is_app_art: IsAppART,
				handle_filters: handleFilters,
			}}>
			<Page
				personas={personasFilter()}
				is_loading={isValidating}
				mutate_personas={mutate}
				handle_copy={handleCopy}
				handle_add_to_proyectos={handleAddToProyectos}
			/>
		</PersonasContext.Provider>
	);
}

/**
 * Método encargado de verificar si un usuario tiene un tipo de APP.
 * @param {*} aplicaciones Colección de aplicaciones.
 * @param {*} tipoAplicacion Tipo de APP buscada.
 * @param {*} aplicacionesUsuario Colección de aplicaciones del usuario.
 * @returns TRUE, si el usuario tiene el tipo de APP, caso contrario FALSE.
 */
function HasApp(aplicaciones, tipoAplicacion, aplicacionesUsuario) {
	let check = Array.from(aplicaciones)
		.filter(a => a.tipo_app === tipoAplicacion)
		.map(a => a._id)
		.some(a => Array.from(aplicacionesUsuario).includes(a));
	return check;
}

export default Index;