import React, { useContext } from "react";
import { Menu, MenuItem, makeStyles, Typography, ListItemIcon } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { PersonasContext } from "./index";

export default function Filtros(props) {
	const {
		anchor_el,
		handle_close,
	} = props;

	const {
		handle_filters,
		is_habilitado,
		is_responsable,
		is_cliente,
		is_externo,
		is_gsuite,
		is_talana,
		is_app_inspecciones,
		is_app_libro_obra,
		is_app_bitacora,
		is_app_sgi,
		is_app_pod,
	} = useContext(PersonasContext);
	const classes = useStyle();

	return (
		<Menu
			anchorEl={anchor_el}
			keepMounted
			open={Boolean(anchor_el)}
			onClose={handle_close}
			transformOrigin={{ horizontal: "right", vertical: "top" }}
			className={classes.menu}
		>
			{/* ITEM HABILITADO */}
			<MenuItem onClick={() => handle_filters("is_habilitado", !is_habilitado)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_habilitado)}
				</ListItemIcon>
				<Typography variant="inherit">¿Habilitado?</Typography>
			</MenuItem>

			{/* ITEM RESPONSABLE */}
			<MenuItem onClick={() => handle_filters("is_responsable", !is_responsable)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_responsable)}
				</ListItemIcon>
				<Typography variant="inherit">¿Responsable?</Typography>
			</MenuItem>

			{/* ITEM CLIENTE */}
			<MenuItem onClick={() => handle_filters("is_cliente", !is_cliente)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_cliente)}
				</ListItemIcon>
				<Typography variant="inherit">¿Cliente?</Typography>
			</MenuItem>

			{/* ITEM EXTERNO */}
			<MenuItem onClick={() => handle_filters("is_externo", !is_externo)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_externo)}
				</ListItemIcon>
				<Typography variant="inherit">¿Externo?</Typography>
			</MenuItem>

			{/* ITEM GSUITE */}
			<MenuItem onClick={() => handle_filters("is_gsuite", !is_gsuite)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_gsuite)}
				</ListItemIcon>
				<Typography variant="inherit">¿GSuite?</Typography>
			</MenuItem>

			{/* ITEM TALANA */}
			<MenuItem onClick={() => handle_filters("is_talana", !is_talana)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_talana)}
				</ListItemIcon>
				<Typography variant="inherit">¿Talana?</Typography>
			</MenuItem>

			{/* ITEM APP INSPECCIONES */}
			<MenuItem onClick={() => handle_filters("is_app_inspecciones", !is_app_inspecciones)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_app_inspecciones)}
				</ListItemIcon>
				<Typography variant="inherit">¿Tiene App de Inspecciones?</Typography>
			</MenuItem>

			{/* ITEM APP LIBRO DE OBRA */}
			<MenuItem onClick={() => handle_filters("is_app_libro_obra", !is_app_libro_obra)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_app_libro_obra)}
				</ListItemIcon>
				<Typography variant="inherit">¿Tiene App de Libre de Obra?</Typography>
			</MenuItem>

			{/* ITEM APP BITACORA */}
			<MenuItem onClick={() => handle_filters("is_app_bitacora", !is_app_bitacora)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_app_bitacora)}
				</ListItemIcon>
				<Typography variant="inherit">¿Tiene App de Bitácora?</Typography>
			</MenuItem>

			{/* ITEM APP SGI */}
			<MenuItem onClick={() => handle_filters("is_app_sgi", !is_app_sgi)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_app_sgi)}
				</ListItemIcon>
				<Typography variant="inherit">¿Tiene App de SGI?</Typography>
			</MenuItem>

			{/* ITEM APP POD */}
			<MenuItem onClick={() => handle_filters("is_app_pod", !is_app_pod)} dense>
				<ListItemIcon>
					{CheckboxIcon(is_app_pod)}
				</ListItemIcon>
				<Typography variant="inherit">¿Tiene App de POD?</Typography>
			</MenuItem>
		</Menu>
	);
}

/**
 * Método encargado de retornar el componente indicado para el estado.
 * @param {*} check Valor del estado.
 * @returns Componente.
 */
function CheckboxIcon(check) {
	if (check) {
		return (
			<CheckBox fontSize="small" />
		);
	} else {
		return (
			<CheckBoxOutlineBlank fontSize="small" />
		);
	}
}

const useStyle = makeStyles((theme) => ({
	menu: {
		minHeight: theme.spacing(50),
		maxHeight: theme.spacing(70),
	}
}));