import React, { useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GetUsuariosExcepcion } from "../../../services/request/usuarios/usuariosExcepcion";
import { GetPersonasGSuiteCyD, GetPersonasTalana } from "../../../services/request/usuarios/personas";

function Index(props) {
	const UsuariosExcepcionSWR = useSWR("usuarios_excepcion", GetUsuariosExcepcion, { revalidateOnFocus: false });
	const PersonasCyDSWR = useSWR("personas_cyd", GetPersonasGSuiteCyD, { revalidateOnFocus: false });
	const PersonasTalanaSWR = useSWR("personas_talana", GetPersonasTalana, { revalidateOnFocus: false });
	const notistack = useSnackbar();

	/**
	 * Método encargado de verificar si se generó un error y mostrar mensaje al usuario, si corresponde.
	 * @param {*} error Error que será verificado.
	 * @param {*} message Mensaje de error.
	 */
	function ErrorMessage(error, message) {
		if (error) {
			notistack.enqueueSnackbar(message, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		}
	}

	useEffect(() => {
		ErrorMessage(UsuariosExcepcionSWR.error, "Error al intentar cargar los datos de los usuarios.");
		ErrorMessage(PersonasCyDSWR.error, "Error al intentar cargar los datos de las personas de CyD.");
		ErrorMessage(PersonasTalanaSWR.error, "Error al intentar cargar los datos de las personas de Talana.");
	}, [UsuariosExcepcionSWR.error, PersonasCyDSWR.error, PersonasTalanaSWR.error]);

	/**
	 * Método encargado de obtener los usuarios con verificación de eliminable.
	 * @returns Colección de usuarios con nueva propiedad.
	 */
	function UsuariosCheck() {
		if (!UsuariosExcepcionSWR.data || !PersonasCyDSWR.data || !PersonasTalanaSWR.data) {
			return [];
		}

		let emailsPersonasCyD = Array.from(PersonasCyDSWR.data).map(p => p.email);
		let emailsPersonasTalana = Array.from(PersonasTalanaSWR.data.data).map(p => p.email);

		let emails = new Set([...emailsPersonasCyD, ...emailsPersonasTalana]);

		let usuarios = Array.from(UsuariosExcepcionSWR.data.data);
		usuarios.forEach((usuario, index) => {
			usuarios[index]["is_deletable"] = emails.has(usuario.email);
		});

		return usuarios;
	}

	/**
	 * Método encargado de verificar si los datos aún están cargando.
	 * @returns TRUE: Los datos aún están cargando | FALSE: Los datos ya cargaron.
	 */
	function IsLoading() {
		let check = UsuariosExcepcionSWR.isValidating
			|| PersonasCyDSWR.isValidating
			|| PersonasTalanaSWR.isValidating;
		return check;
	}

	/**
	 * Método encargado de copiar un dato al portapepeles.
	 * @param {*} mensaje Mensaje para el usuario.
	 * @param {*} id Valor del ID.
	 * @see https://stackoverflow.com/a/52033479 Referencia para Clipboard en React JS.
	 */
	const handleCopy = (mensaje, id) => {
		navigator.clipboard.writeText(id);
		notistack.enqueueSnackbar(`${mensaje} copiado.`);
	}

	return (
		<Page
			usuarios={UsuariosCheck()}
			is_loading={IsLoading()}
			mutate_usuarios={UsuariosExcepcionSWR.mutate}
			handle_copy={handleCopy}
		/>
	);
}

export default Index;