import React, { Fragment, useState, useContext, createContext, useEffect } from "react";
import clsx from "clsx";
import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";
import { AppBar as MaterialAppBar, Avatar, Box, Divider, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { cerrarSesion } from "../../services/auth";
import { MainContext } from "../../App";
import { auth } from "../../services/firebase";
import MenuLateral from "./menuLateral";
import OpcionesHeader from "./opcionesHeader";

import "./style.css";
import "./../../App.css";

const logo_cydocs_color = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";

export const DrawerContext = createContext({
	setOpenMainDrawer: () => { },
	openMainDrawer: true,
});

export default function AppBar(props) {
	const { usuarioSesion } = useContext(MainContext);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const menuId = "primary-search-account-menu";
	const isMenuOpen = Boolean(anchorEl);
	const [claimUser, setClaimUser] = useState(null);
	const [open, setOpen] = useState(isBrowser ? false : false);
	const [openAccesos, setOpenAccesos] = useState(false);
	const [openAccesosAdministracion, setOpenAccesosAdministracion] = useState(false);
	const [openNoticas, setOpenNoticias] = useState(false);

	const loadUsuarioClaims = async () => {
		let resultToken = auth.currentUser
			.getIdTokenResult()
			.then((getIdTokenResult) => {
				setClaimUser(getIdTokenResult.claims);
			});
	}

	const handleDrawerOpen = () => {
		setOpen(!open);
		if (openAccesos === true) {
			setOpenAccesos(false);
		}
		if (openAccesosAdministracion === true) {
			setOpenAccesosAdministracion(false);
		}
		if (openNoticas === true) {
			setOpenNoticias(false);
		}
	}

	function handleProfileMenuOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
	}

	async function handleCerrarSession() {
		setAnchorEl(null);
		await cerrarSesion();
	}

	useEffect(() => {
		loadUsuarioClaims();
	}, []);

	const renderMenu = () => {
		return (
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				id={menuId}
				transformOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
			</Menu>
		);
	};

	const GlobalCss = withStyles({
		"@global": {
			".Mui-selected": {
				borderRadius: "0 25px 25px 0",
			},
			".MuiListItem-button:hover ": {
				borderRadius: "0 25px 25px 0",
			},
		},
	})(() => null);

	return (
		<Fragment>
			<GlobalCss />
			<div className={classes.root}>
				<MaterialAppBar
					position="fixed"
					elevation={0}
					className={clsx(classes.appBar, { [classes.appBarShift]: open })}
				>
					<Box boxShadow={0}>
						<Toolbar
							variant="dense"
							style={{ backgroundColor: "#FFFFFF", paddingLeft: "18px" }}
						>
							<IconButton
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								className={clsx(classes.menuButton)}
							>
								<MenuIcon />
							</IconButton>
							<div>
								<Link to="/">
									<img
										className={classes.img_cyd}
										src={logo_cydocs_color}
										alt="CyDocs"
									/>
								</Link>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<Typography noWrap style={{ color: "#9e9e9e" }}>
									de CyD Ingeniería
								</Typography>
							</div>
							<div className={classes.grow} />
							<Grid
								container
								direction="row"
								alignItems="center"
								style={{
									paddingRight: 10,
								}}
							>
								<OpcionesHeader
									claimUser={claimUser}
								/>
							</Grid>

							<div className={classes.sectionDesktop}>
								<Divider
									orientation="vertical"
									style={{
										height: "3rem",
										marginTop: "0.5rem",
										marginRight: "1rem",
									}}
								/>
								{isBrowser && (
									<Grid container direction="column">
										<div className={classes.contenedorUser}>
											<Grid item xs>
												<Typography
													className={classes.titleNoMargin}
													variant="body2"
													gutterBottom
													color="inherit"
												>
													Bienvenido
												</Typography>
												<div style={{ whiteSpace: "nowrap" }}>
													<Box
														component="div"
														textOverflow="ellipsis"
														overflow="hidden"
													>
														<Typography
															className={classes.titleBold}
															variant="body1"
															color="inherit"
														>
															{usuarioSesion ? usuarioSesion.nombre : ""}
														</Typography>
													</Box>
												</div>
											</Grid>
										</div>
									</Grid>
								)}
								<IconButton
									edge="end"
									aria-label="Account of current user"
									aria-controls={menuId}
									aria-haspopup="true"
									onClick={handleProfileMenuOpen}
									color="inherit"
								>
									<Avatar
										alt="Remy Sharp"
										src={usuarioSesion ? usuarioSesion.photoUrl : ""}
										className={classes.bigAvatar}
									/>
								</IconButton>
							</div>
						</Toolbar>
					</Box>
				</MaterialAppBar>

				{/* COMPONENTE PARA CERRAR SESIÓN */}
				{renderMenu()}

				{/* COMPONENTE DE MENU LATERAL */}
				<MenuLateral
					open={open}
					setOpen={setOpen}
				/>
			</div>
		</Fragment>
	);
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 3,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100%px)`,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	sectionDesktop: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	contenedorUser: {
		marginTop: "auto",
		marginBottom: "auto",
		marginLeft: "20px",
	},
	titleNoMargin: {
		margin: 0,
		textAlign: "end",
		color: "black",
	},
	titleBold: {
		color: "black",
	},
	img_cyd: {
		height: "40px",
	},
}));