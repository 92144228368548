import React from "react";
import { Box, Card, CardActionArea, CardContent, Chip, makeStyles, Tooltip, Typography, withStyles } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";

/**
 * Componente para las aplicaciones de CyDocs.
 * @param {*} action URL de redirección la hacer click.
 * @param {*} link_icon URL del icono de la aplicación.
 * @param {*} text Nombre de la aplicacion.
 * @param {*} text_popup Descripcion de la aplicación.
 * @param {*} button_color Color "primary" o "secondary" del botón.
 * @returns 
 */
export default function CardAppAction(props) {
  const {
    action,
    link_icon,
    text,
    text_popup = "",
    button_color = "primary",
  } = props;

  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "gray",
      color: "white",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
      textAlign: "center",
    },
  }))(Tooltip);

  return (
    <HtmlTooltip title={text_popup}>
      <Card elevation={0} variant="outlined" className={classes.card}>
        <CardActionArea onClick={action}>
          <CardContent>
            {/* ICONO DE APLICACION */}
            <img src={link_icon} alt="fotografia" className={classes.image} />

            {/* NOMBRE DE APLICACION */}
            <Typography variant="h6" className={classes.text}>
              {text}
            </Typography>

            {/* BOTON PARA REDIRECCIONAR */}
            <Box display="flex" justifyContent="flex-end" height="100%">
              <Chip
                label="IR"
                color={button_color}
                onDelete={() => { }}
                deleteIcon={<ChevronRightIcon />}
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </HtmlTooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "20px",
  },
  text: {
    minHeight: 70,
  },
  image: {
    height: "80px",
    paddingBottom: "5px"
  },
}));