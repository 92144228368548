import { useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { QRCodeCanvas } from "qrcode.react";
import { Avatar, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { GetApp, Link } from "@material-ui/icons";
import FileInput from "../../../components/fileInput";
import { CargarImagenCredencial } from "../../../services/request/usuarios/personas";

export default function DialogImage({ is_open, data, handle_close, mutate_personas }) {
	const notistack = useSnackbar();
	const canvasFrontalRef = useRef();
	const canvasReversoRef = useRef();
	const [image, setImage] = useState(null);
	const FilenameFrontalQR = useMemo(() => `${data.run}-frontal.png`, [data]);
	const FilenameReversoQR = useMemo(() => `${data.run}-reverso.png`, [data]);
	const FrontalQR = useMemo(() => `https://credenciales.cydocs.cl/credencial/${data._id}`, [data]);
	const ReversoQR = useMemo(() => `https://credenciales.cydocs.cl/emergencia/${data._id}`, [data]);

	/**
	 * Método encargado de asignar el archivo seleccionado para foto de perfil.
	 * Además, se valida el formato del archivo.
	 * @param {*} name Nombre de la propiedad en formik.
	 * @param {*} e Evento de la selección del archivo.
	 */
	const fileChange = (name, e) => {
		let file = e.target.files[0];
		if (!"image/x-png,image/png,image/jpeg".includes(file.type)) {
			notistack.enqueueSnackbar("El formato de archivo seleccionado es inválido.", { variant: "error" });
		} else {
			file["preview"] = URL.createObjectURL(file);
			setImage(file);
		}
	}

	/**
	 * Método encargado de actualizar la imagen de la credencial.
	 */
	const handleActualizar = async () => {
		try {
			const formData = new FormData();
			formData.append("file", image);
			await CargarImagenCredencial(data._id, formData);
			mutate_personas();
		} catch (error) {
			notistack.enqueueSnackbar("Error al intentar actualizar la imagen de la credencial.", { variant: "error" });
		}
	}

	/**
	 * Método encargado de copiar el link de la imagen.
	 * @param {*} url Link de la imagen.
	 * @param {*} message Mensaje de notificación.
	 */
	const copyURL = (url, message) => {
		navigator.clipboard.writeText(url);
		notistack.enqueueSnackbar(message, { variant: "success" });
	}

	/**
	 * Método encargado de descargar el QR.
	 * @param {*} canvasRef Referencia del canvas QR.
	 * @param {*} name Nombre drel archivo
	 */
	const downloadQR = (canvasRef, name) => {
		const canvas = canvasRef.current.children[0];
		const pngFile = canvas.toDataURL("image/png");

		const downloadLink = document.createElement("a");
		downloadLink.download = name || "Mi QR Generado";
		downloadLink.href = `${pngFile}`;
		downloadLink.click();

		notistack.enqueueSnackbar("Código QR descargado exitosamente.", { variant: "success" });
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" fullWidth>
			<DialogTitle>
				Actualizar imagen
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{`Cambio de foto del usuario y QR para credenciales.`}
				</DialogContentText>

				{/* COMPONENTE IMAGEN ACTUAL Y ACTUALIZACIÓN */}
				<Grid container spacing={2}>
					{/* IMAGEN DE PERFIL */}
					<Grid item xs={12} md={4}>
						<Box display="flex" justifyContent="center">
							<Avatar
								src={data.photo_credential || image?.preview}
								alt="Avatar"
								variant="rounded"
								style={{
									height: 200,
									width: 200,
									border: "1px solid #0000001f",
								}}
							/>
						</Box>
					</Grid>

					{/* SELECTOR DE ARCHIVO */}
					<Grid item xs={12} md={6}>
						<Box display="flex" alignItems="center" style={{ height: "100%", paddingTop: 5, paddingBottom: 5 }}>
							<FileInput
								inputName="file"
								buttonColor="primary"
								buttonVariant="outlined"
								label="Foto de Avatar"
								value={image}
								size="small"
								handleOnChange={fileChange.bind(null, "file")}
								textVariant="outlined"
								accept="image/x-png,image/png,image/jpeg"
							/>
						</Box>
					</Grid>

					{/* BOTÓN PARA ACTUALIZAR */}
					<Grid item xs={12} md={2}>
						<Box display="flex" alignItems="center" style={{ height: "100%", paddingTop: 5, paddingBottom: 5 }}>
							<Button
								onClick={handleActualizar}
								disabled={!image}
								variant="contained"
								color="primary"
								fullWidth
							>
								Actualizar
							</Button>
						</Box>
					</Grid>
				</Grid>

				<Divider style={{ marginTop: 20, marginBottom: 20 }} />

				{/* COMPONENTE QR FRONTAL */}
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Paper elevation={3} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
							<Typography align="center" variant="h6">QR Frontal</Typography>
							{/* IMAGEN DEL QR */}
							<Box display="flex" justifyContent="center">
								<div ref={canvasFrontalRef}>
									<QRCodeCanvas
										value={FrontalQR}
										size={200}
										bgColor="#ffffff"
										fgColor="#000000"
										level="L"
										includeMargin={true}
									/>
								</div>
							</Box>
							<ButtonGroup variant="text" color="primary" fullWidth>
								{/* BOTÓN PARA COPIAR URL */}
								<Button
									onClick={() => copyURL(FrontalQR, "URL Frontal copiada al portapapeles")}
									variant="text"
									color="primary"
									fullWidth
									startIcon={<Link />}
								>
									Copiar URL
								</Button>

								{/* BOTÓN PARA DESCARGAR */}
								<Button
									onClick={() => downloadQR(canvasFrontalRef, FilenameFrontalQR)}
									variant="text"
									color="primary"
									fullWidth
									startIcon={<GetApp />}
								>
									Descargar
								</Button>
							</ButtonGroup>
						</Paper>
					</Grid>

					{/* COMPONENTE QR REVERSO */}
					<Grid item xs={12} md={6}>
						<Paper elevation={3} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
							<Typography align="center" variant="h6">QR Reverso</Typography>
							{/* IMAGEN DEL QR */}
							<Box display="flex" justifyContent="center">
								<div ref={canvasReversoRef}>
									<QRCodeCanvas
										value={ReversoQR}
										size={200}
										bgColor="#ffffff"
										fgColor="#000000"
										level="L"
										includeMargin={true}
									/>
								</div>
							</Box>
							<ButtonGroup variant="text" color="primary" fullWidth>
								{/* BOTÓN PARA COPIAR URL */}
								<Button
									onClick={() => copyURL(ReversoQR, "URL Reverso copiada al portapapeles")}
									variant="text"
									color="primary"
									fullWidth
									startIcon={<Link />}
								>
									Copiar URL
								</Button>

								{/* BOTÓN PARA DESCARGAR */}
								<Button
									onClick={() => downloadQR(canvasReversoRef, FilenameReversoQR)}
									variant="text"
									color="primary"
									fullWidth
									startIcon={<GetApp />}
								>
									Descargar
								</Button>
							</ButtonGroup>
						</Paper>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
			</DialogActions>
		</Dialog>
	);
}