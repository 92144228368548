import React, { useContext } from "react";
import Page from "./page";
import { MainContext } from "../../App";

export default function Index(props) {
	const { permisos } = useContext(MainContext);

	return (
		<Page
			permisos={permisos}
		/>
	);
}