import React, { useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GetPersonasTalana } from "../../../services/request/usuarios/personas";

function Index(props) {
  const { data, isValidating, error } = useSWR("personas_talana", GetPersonasTalana, { revalidateOnFocus: false });
  const notistack = useSnackbar();

  useEffect(() => {
    if (error) {
      notistack.enqueueSnackbar("Error al intentar cargar los datos de las personas desde Talana.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
      });
    }
  }, [notistack, error]);

	/**
	 * Método encargado de copiar un dato al portapepeles.
	 * @param {*} mensaje Mensaje para el usuario.
	 * @param {*} id Valor del ID.
	 * @see https://stackoverflow.com/a/52033479 Referencia para Clipboard en React JS.
	 */
	 const handleCopy = (mensaje, id) => {
		navigator.clipboard.writeText(id);
		notistack.enqueueSnackbar(`${mensaje} copiado.`);
	}

  return (
    <Page
      personas={data}
      is_loading={isValidating}
			handle_copy={handleCopy}
    />
  );
}

export default Index;