import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import Aplicaciones from "./aplicaciones";
import Personas from "./personas";
import PersonasTalana from "./personas_talana";
import Roles from "./roles";
import UsuariosPlataforma from "./usuarios_plataforma";
import UsuariosExcepcion from "./usuarios_excepcion";
import GeneradorQR from "./generador_qr";
import AccessDenied from "../../components/access_denied";
import { APLICACIONES_VER, PERSONAS_VER, PERSONAS_TALANA_VER, ROLES_VER, USUARIOS_EXCEPCION_VER, USUARIOS_PLATAFORMA_VER, USUARIOS_GENERADOR_QR } from "../../constants/permissions";

export default function Page(props) {
	const {
		permisos,
	} = props;

	const [TabIndex, SetTabIndex] = useState(0);
	const location = useLocation();
	const history = useHistory();
	const QueryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const QueryTabs = useMemo(() => [APLICACIONES_VER, PERSONAS_VER, PERSONAS_TALANA_VER, ROLES_VER, USUARIOS_EXCEPCION_VER, USUARIOS_PLATAFORMA_VER, USUARIOS_GENERADOR_QR], []);

	useEffect(() => {
		if (location.search) {
			const index = QueryTabs.findIndex(q => q === QueryParams.get("tab"));
			SetTabIndex(index);
		}
	}, []);

	/**
	 * Método encargado de cambiar la pestaña seleccionada.
	 * Además, se asigna el query param.
	 * @param {*} event Evento.
	 * @param {*} value Index de la pestaña seleccionada.
	 */
	const handleChange = (event, value) => {
		SetTabIndex(value);
		history.replace({ pathname: location.pathname, search: `tab=${QueryTabs[value]}` });
	};

	return (
		<Fragment>
			<AppBar position="static" color="inherit">
				<Tabs value={TabIndex} onChange={handleChange} indicatorColor="primary" variant="fullWidth">
					<Tab label="Aplicaciones" />
					<Tab label="Personas" />
					<Tab label="Personas Talana" />
					<Tab label="Roles" />
					<Tab label="Usuarios Plataforma" />
					<Tab label="Usuarios Excepción" />
					<Tab label="Generador QR" />
				</Tabs>
			</AppBar>
			<TabPanel value={TabIndex} index={0}>
				{VerificarPermiso(permisos, APLICACIONES_VER) ? <Aplicaciones /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={1}>
				{VerificarPermiso(permisos, PERSONAS_VER) ? <Personas /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={2}>
				{VerificarPermiso(permisos, PERSONAS_TALANA_VER) ? <PersonasTalana /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={3}>
				{VerificarPermiso(permisos, ROLES_VER) ? <Roles /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={4}>
				{VerificarPermiso(permisos, USUARIOS_PLATAFORMA_VER) ? <UsuariosPlataforma /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={5}>
				{VerificarPermiso(permisos, USUARIOS_EXCEPCION_VER) ? <UsuariosExcepcion /> : <AccessDenied />}
			</TabPanel>
			<TabPanel value={TabIndex} index={6}>
				{VerificarPermiso(permisos, USUARIOS_GENERADOR_QR) ? <GeneradorQR /> : <AccessDenied />}
			</TabPanel>
		</Fragment>
	);
}

function TabPanel(props) {
	const { children, value, index } = props;

	if (value === index) {
		return (
			<Box p={2}>
				{children}
			</Box>
		);
	} else {
		return null;
	}
}

/**
 * Método encargado de verificar si un permiso está en la colección de permisos del usuario.
 * @param {*} permisos Colección de permisos del usuario.
 * @param {*} permiso Permisos que se verificará.
 * @returns TRUE: si tiene el permiso. FALSE: no tiene el permiso.
 */
function VerificarPermiso(permisos, permiso) {
	let check = Array.from(permisos).includes(permiso);
	return check;
}