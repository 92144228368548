import React, { useState } from "react";
import { Accordion, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, makeStyles, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import Moment from "moment";
import FileInput from "../../../components/fileInput";
import TablaContratosVisibles from "./tablaContratosVisibles";
import TablaProyectosVisibles from "./tablaProyectosVisibles";
import TablaAplicacionesVisibles from "./tablaAplicacionesVisibles";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { AddPersona, GetPersonas } from "../../../services/request/usuarios/personas";
import { GetRoles } from "../../../services/request/usuarios/roles";
import * as ContratosPortafolio from "../../../services/request/portafolio/contratos";
import * as GerenciasPortafolio from "../../../services/request/portafolio/gerencias";
import * as ContratosProyectos from "../../../services/request/proyectos/contratos";
import { uploadFileToCloudStorage } from "../../../services/fileUpload";

export default function DialogAdd(props) {
  let {
    is_open,
    handle_close,
    mutate_personas,
  } = props;

  const [Expanded, SetExpanded] = useState(false);
  const { data: contratosPortafolio, isValidating: isValidatingContratosPortafolio } = useSWR("contratos_portafolio", ContratosPortafolio.GetContratos, { revalidateOnFocus: false });
  const { data: gerenciasPortafolio, isValidating: isValidatingGerenciasPortafolio } = useSWR("gerencias_portafolio", GerenciasPortafolio.GetGerencias, { revalidateOnFocus: false });
  const { data: roles, isValidating: isValidatingRoles } = useSWR("roles", GetRoles, { revalidateOnFocus: false });

  const notistack = useSnackbar();
  const classes = useStyle();

  const formik = useFormik({
    initialValues: FormikInitialValues,
    validationSchema: FormikValidationSchema,
    onSubmit: async (values, helper) => {
      try {
        notistack.enqueueSnackbar("Agregando la nueva persona...", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
        });
        //Se buscan personas con mismo email, teléfono y RUN.
        let responses = await Promise.all([
          GetPersonas({ "contacto.email": values.contacto.email }),
          GetPersonas({ "contacto.telefono": values.contacto.telefono }),
          // GetPersonas({ run: values.run }),
        ]);

        if (responses[0] && values.contacto.email) {
          //Si existe una persona con el mismo email.
          notistack.enqueueSnackbar("El email ingresado ya existe.", {
            variant: "warning",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
          });
        } else if (responses[1] && values.contacto.telefono) {
          //Si existe una persona con el mismo teléfono.
          notistack.enqueueSnackbar("El teléfono ingresado ya existe.", {
            variant: "warning",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
          });
          // } else if (responses[2] && values.run) {
          //   //Si existe una persona con el mismo RUN.
          //   notistack.enqueueSnackbar("El RUN ingresado ya existe.", {
          //     variant: "warning",
          //     anchorOrigin: {
          //       horizontal: "center",
          //       vertical: "bottom"
          //     },
          //     action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
          //   });
        } else {
          //Si ninguno de los datos (email, teléfono y RUN) existe.
          values["gerencia_ref"] = values.gerencia._id;
          values["centro_costos_ref"] = [{
            contrato_id: values.centro_costo._id,
            codigo: values.centro_costo.codigo,
            nombre: values.centro_costo.nombre,
          }];
          values["contratos_ref"] = [{
            contrato_id: values.contrato._id,
            codigo: values.contrato.codigo,
            nombre: values.contrato.nombre,
          }];
          values["rol_ref"] = values.rol._id;

          if (values.file) {
            //Si se seleccionó una foto de perfil.
            let filename = FilenameFormat(values.nombre, values.apellido_paterno, values.apellido_materno);
            let url = await uploadFileToCloudStorage(values.file, "avatars", filename);
            values.photo = url;
          }
          values.password = PasswordFormat(values.nombre, values.apellido_paterno);
          await AddPersona(values);
          notistack.closeSnackbar();
          notistack.enqueueSnackbar("Persona agregada exitosamente.", {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
          });
        }
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar agregar la personas.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
        });
      } finally {
        helper.resetForm();
        mutate_personas();
        handleCloseDialog();
      }
    }
  });

  /**
   * Método encargado de expandir el panel de visibilidad seleccionado.
   * @param {*} panel Panel seleccionado.
   */
  const handleChangePanel = (panel) => (event, isExpanded) => {
    SetExpanded(isExpanded ? panel : false);
  };

  /**
   * Método encargado de agregar o quitar la visibilidad de un contrato.
   * Además, se incluyen los proyectos, si la opción está seleccionada.
   * @param {*} contratoID ID del contrato.
   * @param {*} check Estado de la visibilidad.
   */
  const handleAddRemoveContrato = (contratoID, check) => {
    let contratos = formik.values.contratos_visibles_ref;
    let index = contratos.indexOf(contratoID);
    if (index !== -1) {
      //Si ya contiene el contratoID, se elimina.
      contratos.splice(index, 1);
      formik.setFieldValue("contratos_visibles_ref", contratos);

      //Se obtiene el contrato por su ID.
      ContratosProyectos.GetContrato(contratoID)
        .then(contrato => {
          //Se eliminan todos los proyectos visibles, de ese contrato.
          let proyectosFilter = formik.values.proyectos_visibles_ref.filter(p => !contrato.proyectos_ref.includes(p));
          formik.setFieldValue("proyectos_visibles_ref", proyectosFilter);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      //Si no contiene el contratoID, se agrega.
      contratos.push(contratoID);
      formik.setFieldValue("contratos_visibles_ref", contratos);
      if (check) {
        //Se obtiene el contrato por su ID.
        ContratosProyectos.GetContrato(contratoID)
          .then(contrato => {
            let proyectosVisibles = [...new Set([...formik.values.proyectos_visibles_ref, ...contrato.proyectos_ref])];
            formik.setFieldValue("proyectos_visibles_ref", proyectosVisibles);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }

  /**
   * Método encargado de agregar o quitar la visibilidad de un proyecto.
   * @param {*} proyectoID ID del proyecto.
   */
  const handleAddRemoveProyecto = (proyectoID) => {
    let proyectos = formik.values.proyectos_visibles_ref;
    let index = proyectos.indexOf(proyectoID);
    if (index !== -1) {
      //Si ya contiene el proyectoID, se elimina.
      proyectos.splice(index, 1);
      formik.setFieldValue("proyectos_visibles_ref", proyectos);
    } else {
      //Si no contiene el proyectoID, se agrega.
      proyectos.push(proyectoID);
      formik.setFieldValue("proyectos_visibles_ref", proyectos);
    }
  }

  /**
   * Método encargado de agregar o quitar la visibilidad de una aplicación.
   * @param {*} aplicacionID ID de la aplicación.
   */
  const handleAddRemoveAplicacion = (aplicacionID) => {
    let aplicaciones = formik.values.aplicaciones_visibles_ref;
    let index = aplicaciones.indexOf(aplicacionID);
    if (index !== -1) {
      //Si ya contiene el aplicacionID, se elimina.
      aplicaciones.splice(index, 1);
      formik.setFieldValue("aplicaciones_visibles_ref", aplicaciones);
    } else {
      //Si no contiene el aplicacionID, se agrega.
      aplicaciones.push(aplicacionID);
      formik.setFieldValue("aplicaciones_visibles_ref", aplicaciones);
    }
  }

  /**
   * Método encargado de cerrar el popup y cerrar los paneles.
   */
  const handleCloseDialog = () => {
    handle_close();
    SetExpanded(false);
    formik.resetForm();
  }

  /**
   * Método encargado de asignar el archivo seleccionado para foto de perfil.
   * Además, se valida el formato del archivo.
   * @param {*} name Nombre de la propiedad en formik.
   * @param {*} e Evento de la selección del archivo.
   */
  const fileChange = (name, e) => {
    let file = e.target.files[0];
    if (!"image/x-png,image/png,image/jpeg".includes(file.type)) {
      notistack.enqueueSnackbar("El formato de archivo seleccionado es inválido.", { variant: "error" });
    } else {
      formik.setFieldValue(name, file);
      formik.setFieldTouched(name, true, false);
    }
  };

  return (
    <Dialog open={is_open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
      <DialogTitle>Agregar Persona</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Ingrese la información requerida de la persona y luego guarde los cambios."}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              name="run"
              label="RUN"
              value={formik.values.run}
              onChange={formik.handleChange}
              helperText={formik.errors.run}
              error={Boolean(formik.errors.run)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="nombre"
              label="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              helperText={formik.errors.nombre}
              error={Boolean(formik.errors.nombre)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="apellido_paterno"
              label="Apellido Paterno"
              value={formik.values.apellido_paterno}
              onChange={formik.handleChange}
              helperText={formik.errors.apellido_paterno}
              error={Boolean(formik.errors.apellido_paterno)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="apellido_materno"
              label="Apellido Materno"
              value={formik.values.apellido_materno}
              onChange={formik.handleChange}
              helperText={formik.errors.apellido_materno}
              error={Boolean(formik.errors.apellido_materno)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="cargo"
              label="Cargo"
              value={formik.values.cargo}
              onChange={formik.handleChange}
              helperText={formik.errors.cargo}
              error={Boolean(formik.errors.cargo)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="titulo"
              label="Título"
              value={formik.values.titulo}
              onChange={formik.handleChange}
              helperText={formik.errors.titulo}
              error={Boolean(formik.errors.titulo)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              error={Boolean(formik.errors.file)}
              fullWidth
            >
              <FileInput
                inputName="file"
                meta={{ touched: formik.touched.file, errors: formik.errors.file }}
                buttonColor="primary"
                buttonVariant="outlined"
                label="Foto de Avatar"
                value={formik.values.file}
                handleOnChange={fileChange.bind(null, "file")}
                textVariant="outlined"
                accept="image/x-png,image/png,image/jpeg"
              />
              <FormHelperText>
                {formik.touched.file && formik.errors.file ? formik.errors.file : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.email"
              label="Email"
              value={formik.values.contacto.email}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.email : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.email : "")}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.email_alternativo"
              label="Email Alternativo"
              value={formik.values.contacto.email_alternativo}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.email_alternativo : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.email_alternativo : "")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.email_personal"
              label="Email Personal"
              value={formik.values.contacto.email_personal}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.email_personal : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.email_personal : "")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.telefono"
              label="Teléfono"
              value={formik.values.contacto.telefono}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.telefono : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.telefono : "")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.telefono_alternativo"
              label="Teléfono Alternativo"
              value={formik.values.contacto.telefono_alternativo}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.telefono_alternativo : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.telefono_alternativo : "")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="contacto.telefono_personal"
              label="Teléfono Personal"
              value={formik.values.contacto.telefono_personal}
              onChange={formik.handleChange}
              helperText={formik.errors.contacto ? formik.errors.contacto.telefono_personal : ""}
              error={Boolean(formik.errors.contacto ? formik.errors.contacto.telefono_personal : "")}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={gerenciasPortafolio}
              value={formik.values.gerencia}
              onChange={(event, value) => formik.setFieldValue("gerencia", value)}
              getOptionLabel={gerencia => `[${gerencia.sigla}] ${gerencia.nombre}`}
              loading={isValidatingGerenciasPortafolio}
              noOptionsText="Sin Datos"
              renderInput={(params) => (
                <Tooltip title="Obtenidos desde Portafolio" placement="top">
                  <TextField
                    label="Gerencia"
                    variant="outlined"
                    helperText={formik.errors.gerencia}
                    error={Boolean(formik.errors.gerencia)}
                    required
                    {...params}
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={contratosPortafolio?.filter(c => c.gerencia_ref._id === formik.values.gerencia?._id)}
              value={formik.values.contrato}
              onChange={(event, value) => formik.setFieldValue("contrato", value)}
              getOptionLabel={contrato => `[${contrato.codigo}] ${contrato.nombre}`}
              loading={isValidatingContratosPortafolio}
              disabled={!formik.values.gerencia}
              noOptionsText="Sin Datos"
              renderInput={(params) => (
                <Tooltip title="Obtenidos desde Portafolio" placement="top">
                  <TextField
                    label="Contrato"
                    variant="outlined"
                    helperText={formik.errors.contrato}
                    error={Boolean(formik.errors.contrato)}
                    required
                    {...params}
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={contratosPortafolio}
              value={formik.values.centro_costo}
              onChange={(event, value) => formik.setFieldValue("centro_costo", value)}
              getOptionLabel={centroCosto => `[${centroCosto.codigo}] ${centroCosto.nombre}`}
              loading={isValidatingContratosPortafolio}
              noOptionsText="Sin Datos"
              renderInput={(params) => (
                <Tooltip title="Obtenidos desde Portafolio" placement="top">
                  <TextField
                    label="Centro de Costo"
                    variant="outlined"
                    helperText={formik.errors.centro_costo}
                    error={Boolean(formik.errors.centro_costo)}
                    required
                    {...params}
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={roles}
              value={formik.values.rol}
              onChange={(event, value) => formik.setFieldValue("rol", value)}
              getOptionLabel={rol => rol.nombre}
              loading={isValidatingRoles}
              renderInput={(params) => (
                <TextField
                  label="Rol"
                  variant="outlined"
                  helperText={formik.errors.rol}
                  error={Boolean(formik.errors.rol)}
                  required
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={3}>
              <FormControlLabel
                label="¿Está Habilitado?"
                control={(
                  <Checkbox
                    name="is_habilitado"
                    onChange={(event, checked) => formik.setFieldValue("is_habilitado", checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} >
              <FormControlLabel
                label="¿Es Responsable?"
                control={(
                  <Checkbox
                    name="is_responsable"
                    onChange={(event, checked) => formik.setFieldValue("is_responsable", checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                label="¿Es Cliente?"
                control={(
                  <Checkbox
                    name="is_cliente"
                    onChange={(event, checked) => formik.setFieldValue("is_cliente", checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                label="¿Es Externo?"
                control={(
                  <Checkbox
                    name="is_externo"
                    onChange={(event, checked) => formik.setFieldValue("is_externo", checked)}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* VISIBILIDADES */}
          <Grid item xs={12}>
            <Paper elevation={5} className={classes.paper}>
              {/* CONTRATOS */}
              <Accordion
                expanded={Expanded === "panel1"}
                onChange={handleChangePanel("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Contratos Visibles</Typography>
                </AccordionSummary>
                <TablaContratosVisibles
                  seleccionados={formik.values.contratos_visibles_ref}
                  handle_toggle={handleAddRemoveContrato}
                />
              </Accordion>

              {/* PROYECTOS */}
              <Accordion
                expanded={Expanded === "panel2"}
                onChange={handleChangePanel("panel2")}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Proyectos Visibles</Typography>
                </AccordionSummary>
                <TablaProyectosVisibles
                  seleccionados={formik.values.proyectos_visibles_ref}
                  contratos={formik.values.contratos_visibles_ref}
                  handle_toggle={handleAddRemoveProyecto}
                />
              </Accordion>

              {/* APLICACIONES */}
              <Accordion
                expanded={Expanded === "panel3"}
                onChange={handleChangePanel("panel3")}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Aplicaciones Visibles</Typography>
                </AccordionSummary>
                <TablaAplicacionesVisibles
                  seleccionados={formik.values.aplicaciones_visibles_ref}
                  handle_toggle={handleAddRemoveAplicacion}
                />
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
        <Button onClick={formik.submitForm} variant="contained" color="primary">Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Método encargado de generar el nombre de la foto de perfil de una persona.
 * @param {*} nombre Nombre de la persona.
 * @param {*} apellidoPaterno Apellido paterno de la persona.
 * @param {*} apellidoMaterno Apellido materno de la persona.
 * @returns Nombre de la foto de perfil formateado.
 */
function FilenameFormat(nombre, apellidoPaterno, apellidoMaterno) {
  let parte1 = String(nombre).trim().replace(/ /g, "_").toLowerCase();
  let parte2 = String(apellidoPaterno).trim().replace(/ /g, "_").toLowerCase();
  let parte3 = String(apellidoMaterno).trim().replace(/ /g, "_").toLowerCase();
  let parte4 = Moment().format("DD_MM_YYYY_HH_mm_ss");
  let filename = `foto_perfil_${parte1}_${parte2}_${parte3}_${parte4}}`;
  return filename;
}

/**
 * Método encargado de generar la contraseña de la persona.
 * @param {*} nombre Nombre de la persona.
 * @param {*} apellidoPaterno Apellido paterno de la persona.
 * @returns Contraseña formateada [Primera Letra Nombre][Apellido][1234].
 */
function PasswordFormat(nombre, apellidoPaterno) {
  let parte1 = RemoveSpecialCharacter(nombre).trim().replace(/ /g, "").toLowerCase();
  let parte2 = RemoveSpecialCharacter(apellidoPaterno).trim().replace(/ /g, "").toLowerCase();
  let password = `${parte1[0]}${parte2}1234`;
  return password;
}

/**
 * Método encargado de eliminar caracteres especiales de un texto
 * @param {*} text Texto que será limpiado.
 * @returns Texto sin caracteres especiales.
 */
function RemoveSpecialCharacter(text) {
  let cleanText = String(text)
    .replace(/Á/g, "a").replace(/á/g, "a").replace(/Ä/g, "a").replace(/ä/g, "a").replace(/Â/g, "a").replace(/â/g, "a")
    .replace(/É/g, "e").replace(/é/g, "e").replace(/Ë/g, "e").replace(/ë/g, "e").replace(/Ê/g, "e").replace(/ê/g, "e")
    .replace(/Í/g, "i").replace(/í/g, "i").replace(/Ï/g, "i").replace(/ï/g, "i").replace(/Î/g, "i").replace(/î/g, "i")
    .replace(/Ó/g, "o").replace(/ó/g, "o").replace(/Ö/g, "o").replace(/ö/g, "o").replace(/Ô/g, "o").replace(/ô/g, "o")
    .replace(/Ú/g, "u").replace(/ú/g, "u").replace(/Ü/g, "u").replace(/ü/g, "u").replace(/Û/g, "u").replace(/û/g, "u")
    .replace(/Ñ/g, "n").replace(/ñ/g, "n");
  return cleanText;
}

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  }
}));