import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { UpdateRol } from "../../../services/request/usuarios/roles";
import { FormikInitialValues, FormikValidationSchema } from "./utils";

export default function DialogUpdate(props) {
  const {
    is_open,
    data,
    handle_close,
    mutate_roles,
  } = props;

  const notistack = useSnackbar();

  const formik = useFormik({
    initialValues: FormikInitialValues,
    validationSchema: FormikValidationSchema,
    onSubmit: async (values, helper) => {
      try {
        notistack.enqueueSnackbar("Actualizando el rol...", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
        });
        values["_id"] = data._id;
        await UpdateRol(values);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar("Rol actualizado exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
        });
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar actualizar el rol.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
        });
      } finally {
        helper.resetForm();
        mutate_roles();
        handle_close();
      }
    }
  });

  const loadData = () => {
    formik.resetForm({
      values: {
        nombre: data.nombre,
        descripcion: data.descripcion,
      }
    });
  }

  /**
   * Método encargado de cerrar el popup y cerrar los paneles.
   */
  const handleCloseDialog = () => {
    handle_close();
    formik.resetForm();
  }

  return (
    <Dialog open={is_open} onClose={handleCloseDialog} onEntered={loadData} maxWidth="md">
      <DialogTitle>Actualizar Rol</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Actualice la información necesaria del rol y luego guarde los cambios."}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="nombre"
              label="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              helperText={formik.errors.nombre}
              error={Boolean(formik.errors.nombre)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="descripcion"
              label="Descripción"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              helperText={formik.errors.descripcion}
              error={Boolean(formik.errors.descripcion)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
        <Button onClick={formik.submitForm} variant="contained" color="primary">Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
}