import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { UpdateUsuarioExcepcion } from "../../../services/request/usuarios/usuariosExcepcion";
import { FormikInitialValues, FormikValidationSchema } from "./utils";

export default function DialogUpdate(props) {
  const {
    is_open,
    data,
    handle_close,
    mutate_usuarios,
  } = props;

  const notistack = useSnackbar();

  const formik = useFormik({
    initialValues: FormikInitialValues,
    validationSchema: FormikValidationSchema,
    onSubmit: async (values, helper) => {
      try {
        notistack.enqueueSnackbar("Actualizando la excepción de usuario...", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
        });
        values["_id"] = data._id;
        await UpdateUsuarioExcepcion(values);
        notistack.closeSnackbar();
        notistack.enqueueSnackbar("Excepción de usuario actualizada exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
        });
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar actualizar la excepción de usuario.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
        });
      } finally {
        helper.resetForm();
        mutate_usuarios();
        handle_close();
      }
    }
  });

  const loadData = () => {
    formik.resetForm({
      values: {
        email: data.email,
        razon: data.razon,
      }
    });
  }

  /**
   * Método encargado de cerrar el popup y cerrar los paneles.
   */
  const handleCloseDialog = () => {
    handle_close();
    formik.resetForm();
  }

  return (
    <Dialog open={is_open} onClose={handleCloseDialog} onEntered={loadData} maxWidth="md">
      <DialogTitle>Actualizar Excepción de Usuario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Actualice la información necesaria de la excepción de usuario y luego guarde los cambios."}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.errors.email}
              error={Boolean(formik.errors.email)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="razon"
              label="Razón"
              value={formik.values.razon}
              onChange={formik.handleChange}
              helperText={formik.errors.razon}
              error={Boolean(formik.errors.razon)}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
        <Button onClick={formik.submitForm} variant="contained" color="primary">Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
}