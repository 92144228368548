import React, { useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GetUsuariosPlataforma } from "../../../services/request/usuarios/usuariosPlataforma";

function Index(props) {
	const { data, isValidating, mutate, error } = useSWR("usuarios", GetUsuariosPlataforma, { revalidateOnFocus: false });
	const notistack = useSnackbar();

	useEffect(() => {
		if (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar cargar los datos de los usuarios.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		}
	}, [error, notistack]);

	return (
		<Page
			usuarios={data}
			is_loading={isValidating}
			mutate_usuarios={mutate}
		/>
	);
}

export default Index;