import React from "react";
import { Switch } from "@material-ui/core";
import useSWR from "swr";
import MaterialTable from "../../../components/materialTable";
import { GetAplicaciones } from "../../../services/request/usuarios/aplicaciones";

export default function TablaAplicacionesVisibles(props) {
  const {
    seleccionados,
    handle_toggle,
  } = props;

  const { data: aplicaciones, isValidating: isValidatingAplicaciones } = useSWR("aplicaciones", GetAplicaciones, { revalidateOnFocus: false });

  if (aplicaciones) {
    //Se incluye flag CHECKED.
    aplicaciones.forEach((aplicacion, index) => {
      let check = seleccionados.includes(aplicacion._id);
      aplicaciones[index].checked = check;
    });

    //Se ordena por los aplicaciones seleccionados.
    aplicaciones.sort((a, b) => b.checked - a.checked);
  }

  let columns = [
    {
      title: "Visibilidad",
      removable: false,
      sorting: false,
      render: row => <Switch checked={row.checked} onClick={(event) => handle_toggle(row._id)} color="primary" />,
    }, {
      title: "Nombre",
      field: "nombre",
      render: (row) => <label style={{ width: 250 }}>{row.nombre}</label>,
    }, {
      title: "Tipo",
      field: "tipo",
    }, {
      title: "Categoría",
      field: "categoria",
    }, {
      title: "Contrato",
      field: "contrato_ref.codigo",
    }
  ];

  return (
    <MaterialTable
      title={CalcularCantidades(seleccionados, aplicaciones)}
      is_loading={isValidatingAplicaciones}
      data={aplicaciones}
      columns={columns}
      filtering={false}
      grouping={false}
      exportButton={false}
    />
  );
}

/**
 * Método encargado de calcular la cantidad de seleccionados sobre el total.
 * @param {*} seleccionados Cantidad de seleccionados.
 * @param {*} total Cantidad total.
 * @returns Cantidades con formato X1 / X2.
 */
function CalcularCantidades(seleccionados, total) {
  let cantidad1 = seleccionados ? seleccionados.length : 0;
  let cantidad2 = total ? total.length : 0;
  return `Aplicaciones Seleccionadas: ${cantidad1} / ${cantidad2}`;
}