import React from "react";
import { AppBar, Tab, Tabs, withStyles } from "@material-ui/core";

/**
 * Componente para listado de pestañas simple.
 * @param {*} tab_list Colección de textos de pestañas.
 * @param {*} tab_index Número de la pestaña actual.
 * @param {*} handle_change Método encargado de cambiar la pestaña.
 * @returns Component.
 */
export default function TabSelector(props) {
	const { tab_list, tab_index, handle_change } = props;

	return (
		<AppBar position="static" color="transparent">
			<Tabs
				value={tab_index}
				onChange={handle_change}
				variant="fullWidth"
				TabIndicatorProps={{ hidden: true }}
			>
				{Array.from(tab_list).map((tabItem, index) => (
					<CustomTab label={tabItem} key={`custom_tab_${index}`} />
				))}
			</Tabs>
		</AppBar>
	);
}

const CustomTab = withStyles({
	root: {
		color: "black",
	},
	selected: {
		backgroundColor: '#802528',
		color: "white",
		borderTopLeftRadius: "25px",
		borderTopRightRadius: "25px",
		borderBottomLeftRadius: "0px",
		borderBottomRightRadius: "0px",
	},
})(Tab);