import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function GalleryCarousel(props) {
  const classes = useStyles();

  var items = [
    {
      imagen: "https://storage.googleapis.com/mi-cyd/CyDocs/Popup_Android.png",
      link: "https://play.google.com/store/apps/details?id=cl.cydingenieria.cydocs_proyecto",
    }, {
      imagen: "https://storage.googleapis.com/mi-cyd/CyDocs/Popup_Apple.png",
      link: "https://apps.apple.com/cl/app/cydocs/id1479406727",
    },
  ];

  return (
    <React.Fragment>
      <Carousel
        autoPlay={false}
        indicators={true}
        navButtonsAlwaysVisible={true}
        animation="slide"
        fullHeightHover={true}
      >
        {items.map((item, index) => (
          <Box className={classes.box} key={`box_${index}`}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <img src={item.imagen} alt="fotografia" className={classes.image} />
            </a>
          </Box>
        ))}
      </Carousel>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
  },
  image: {
    height: "580px",
  },
}));

export default GalleryCarousel;
