import React from "react";
import { Box, Card, CardActionArea, CardContent, Chip, makeStyles, Typography } from "@material-ui/core";
import {
  Android as AndroidIcon,
  Apple as AppleIcon,
  Computer as ComputerIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";

/**
 * Componente para aplicaciones destacadas con popup.
 * @param {*} action Función a ejecutar en click.
 * @param {*} link_icon URL del icono de la aplicación.
 * @param {*} text Nombre de la aplicacion.
 * @param {*} text_more Descripción de la aplicación.
 * @param {*} text_button Texto del botón.
 * @param {*} on_android Flag para mostrar si está disponible en Android.
 * @param {*} on_apple Flag para mostrar si está disponible en Apple.
 * @param {*} on_computer Flag para mostrar si está disponible en Computador.
 * @returns 
 */
export default function CardAppDestacadaDescargable(props) {
  const {
    action,
    link_icon,
    text,
    text_more,
    text_button,
    on_android = false,
    on_apple = false,
    on_computer = false,
  } = props;

  const classes = useStyles();

  return (
    <Card elevation={0} variant="outlined" className={classes.card}>
      <CardActionArea onClick={action}>
        <CardContent className={classes.card_contenido}>
          {/* ICONO DE APLICACION */}
          <img src={link_icon} alt="fotografia" className={classes.image} />

          {/* NOMBRE DE APLICACION */}
          <Typography gutterBottom variant="h5" component="h2">
            {text}
          </Typography>

          {/* DESCRIPCION DE APLICACION */}
          <Typography variant="body2" color="textSecondary" align="left" className={classes.text_more}>
            {text_more}
          </Typography>

          <Box display="flex">
            {/* DISPONIBLES EN SISTEMAS OPERATIVOS */}
            <Box flexGrow={1}>
              {on_android && (
                <AndroidIcon
                  fontSize="large"
                  className={classes.icono}
                />
              )}
              {on_apple && (
                <AppleIcon
                  fontSize="large"
                  className={classes.icono}
                />
              )}
              {on_computer && (
                <ComputerIcon
                  fontSize="large"
                  className={classes.icono}
                />
              )}
            </Box>

            {/* BOTON PARA REDIRECCIONAR */}
            <Box>
              <Chip
                label={text_button}
                color="primary"
                onDelete={() => { }}
                deleteIcon={<ChevronRightIcon />}
              />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "20px",
  },
  card_contenido: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
  },
  icono: {
    color: "gray",
  },
  image: {
    height: "80px",
    paddingBottom: "5px"
  },
  text_more: {
    alignItems: "center",
    height: "113px",
  }
}));