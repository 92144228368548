import Axios from "axios";
import { PROYECTOS } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los proyectos.
 * @returns Colección de proyectos.
 */
export async function GetProyectos() {
  try {
    let url = `${PROYECTOS}/proyectos`;
    let response = await Axios.get(url);
    console.log(response)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los proyectos, con detalle de su información de contrato.
 * @returns Colección de proyectos.
 */
export async function GetProyectosContrato() {
  try {
    let url_proyectos = `${PROYECTOS}/proyectos`;
    let url_contratos = `${PROYECTOS}/contratos`;
    let responses = await Promise.all([
      Axios.get(url_proyectos),
      Axios.get(url_contratos),
    ]);
    let proyectos = responses[0].data;
    let contratos = responses[1].data;

    proyectos.forEach((proyecto, index) => {
      let contrato = contratos.find(c => c._id === proyecto.contrato_ref);
      proyectos[index]["contrato_codigo"] = contrato.codigo;
      proyectos[index]["contrato_nombre"] = contrato.nombre;
    });

    return proyectos;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener un proyecto, por su ID.
 * @param {*} proyectoID ID del proyecto.
 * @returns Colección de proyectos.
 */
export async function GetProyecto(proyectoID) {
  try {
    let url = `${PROYECTOS}/proyectos/${proyectoID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}