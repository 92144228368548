import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";

/**
   * Método encargado de obtener todos los usuarios plataforma.
   * @returns Colección de usuarios plataforma.
   */
export async function GetUsuariosPlataforma() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma/_persona_ref.contacto/_persona_ref.nombre/_persona_ref.apellido_paterno/_persona_ref.apellido_materno/permisos`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
   * Método encargado de obtener un usuario plataforma, por su ID.
   * @param {*} usuarioPlataformaID ID del usuario plataforma.
   * @returns Datos del usuario plataforma.
   */
export async function GetUsuarioPlataforma(usuarioPlataformaID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma/${usuarioPlataformaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
   * Método encargado de agregar un usuario plataforma.
   * @param {*} usuarioPlataforma Datos del usuario plataforma.
   * @returns Dato agregado.
   */
export async function AddUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma`;
    let response = await Axios.post(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
   * Método encargado de actualizar un usuario plataforma.
   * @param {*} usuarioPlataforma Datos del usuario plataforma.
   * @returns Dato actualizado.
   */
export async function UpdateUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma/${usuarioPlataforma._id}`;
    let response = await Axios.put(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
   * Método encargado de eliminar un usuario plataforma.
   * @param {*} usuarioPlataformaID ID del usuario plataforma.
   * @returns Respuesta de la eliminación.
   */
export async function DeleteUsuarioPlataforma(usuarioPlataformaID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma/${usuarioPlataformaID}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}